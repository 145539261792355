import { createContext, useContext, useState } from 'react'

export type LayoutProperty = {
  /** ヘッダーの高さ（DefaultLayoutで更新） */
  headerHeight: number
  setHeaderHeight: (headerHeight: number) => void

  /* モバイルのカテゴリメニューの高さ（DefaultLayoutで更新） */
  mobileCategoriesMenuHeight: number
  setMobileCategoriesMenuHeight: (mobileCategoriesMenuHeight: number) => void
}

export const LayoutPropertyContext = createContext<LayoutProperty>({
  headerHeight: 0,
  mobileCategoriesMenuHeight: 0,
  setHeaderHeight: () => {
    throw new Error('Unexpected.')
  },
  setMobileCategoriesMenuHeight: () => {
    throw new Error('Unexpected.')
  },
})

type Props = {
  children: React.ReactNode
}

export const LayoutPropertyProvider = ({ children }: Props) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [mobileCategoriesMenuHeight, setMobileCategoriesMenuHeight] =
    useState(0)
  return (
    <LayoutPropertyContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        mobileCategoriesMenuHeight,
        setMobileCategoriesMenuHeight,
      }}
    >
      {children}
    </LayoutPropertyContext.Provider>
  )
}

export const useLayoutProperty = () => useContext(LayoutPropertyContext)
