import { useMemo, useRef } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { pageRef, textbookDraftRef } from '../../firebase'
import { isBlank } from '../../utils'

export const useTextbookDraft = (
  id: string | null | undefined,
  pageId?: string | null | undefined
) => {
  const _textbookDraftRef =
    !isBlank(id) && id !== '' ? textbookDraftRef(id) : null
  const [textbookDraft, textbookDraftLoading, textbookDraftError] =
    useDocumentData(_textbookDraftRef)
  const notStartedFetchingDraft = useRef(true)
  const waitingTextbookDraft = useMemo(() => {
    //  _textbookDraftRefが有効になって最初のloadが終わるタイミングまでtrue
    if (_textbookDraftRef === null) return true
    else if (notStartedFetchingDraft.current && !textbookDraftLoading)
      return true
    else if (notStartedFetchingDraft.current && textbookDraftLoading) {
      notStartedFetchingDraft.current = false
      return true
    } else if (!notStartedFetchingDraft.current && textbookDraftLoading) {
      return true
    } else {
      return false
    }
  }, [textbookDraftLoading, _textbookDraftRef])
  const notFoundTextbookDraft =
    (!waitingTextbookDraft && textbookDraft === undefined) || id === null

  const _pageRef =
    _textbookDraftRef !== null && !isBlank(pageId) && pageId !== ''
      ? pageRef(_textbookDraftRef, pageId)
      : null
  const [page, pageLoading, pageError] = useDocumentData(_pageRef)
  const notStartedFetchingPage = useRef(true)
  const waitingPage = useMemo(() => {
    //  _pageRefが有効になって最初のloadが終わるタイミングまでtrue
    if (_pageRef === null) return true
    else if (notStartedFetchingPage.current && !pageLoading) return true
    else if (notStartedFetchingPage.current && pageLoading) {
      notStartedFetchingPage.current = false
      return true
    } else if (!notStartedFetchingPage.current && pageLoading) {
      return true
    } else {
      return false
    }
  }, [pageLoading, _pageRef])
  const notFoundPage = (!waitingPage && page === undefined) || pageId === null

  return {
    textbookDraft,
    textbookDraftError,
    waitingTextbookDraft,
    notFoundTextbookDraft,
    page,
    pageError,
    waitingPage,
    notFoundPage,
  }
}
