import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import { useEffect } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { RecoilRoot } from 'recoil'
import { MathdownConfig } from 'web_core/assets/mathdown'
import { PopupMessageProvider } from 'web_core/assets/messenger/context/popup_message'
import { AccountProvider } from 'web_core/context/account'
import { AuthProvider } from 'web_core/firebase/context'
import { logInit } from 'web_core/utils'
import { LayoutPropertyProvider } from '~/context/layout_property'

// global空間のCSSを読み込み
import '~/styles/globals.scss'

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // logの初期設定
    const hotjarSiteId = 3720259
    const hotjarVersion = 6
    logInit({ siteId: hotjarSiteId, version: hotjarVersion })
  }, [])

  return (
    <SSRProvider>
      <RecoilRoot>
        <LayoutPropertyProvider>
          <PopupMessageProvider>
            <AuthProvider>
              <AccountProvider>
                <MathdownConfig>
                  <Component {...pageProps} />
                </MathdownConfig>{' '}
              </AccountProvider>
            </AuthProvider>
          </PopupMessageProvider>
        </LayoutPropertyProvider>
      </RecoilRoot>
    </SSRProvider>
  )
}

export default appWithTranslation(MyApp)
