import { collection, db, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { LaTeXFileSubcols } from './subcollections'

// Cloud Storage path
// "uploads/mathdown/{id}.{png/jpeg/gif}"
export interface LaTeXFile extends Document {
  readonly ref: DocRef<LaTeXFile>
  readonly parent_id: 'latex_files'
  readonly subcollections: typeof LaTeXFileSubcols
  created_by: string
  filename: string
  full_path: string
  url: string
}

export const LaTeXFileConverter = getConverter<LaTeXFile>(LaTeXFileSubcols)
export const LaTeXFileRef = collection<LaTeXFile>(
  db,
  'latex_files'
).withConverter(LaTeXFileConverter)
